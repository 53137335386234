import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import JobSheetValidationSchema from "../Validation/JobSheetValidationSchema";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import FormSection from "../../../../Core/Forms/FormSection";
import AssignToTeamLeader from "../../../Common/Components/AssignToTeamLeader";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import SiteDetails from "../../../Common/Components/SiteDetails";
import JobRequirements from "../../../Common/Components/JobRequirements";
import JobProductDetails from "../../SalesQuotes/Details/JobProductDetails";
import JobDescriptions from "../../SalesQuotes/Details/JobDescriptions";
import Photos from "../../../Common/Components/Photos";
import SiteLeaderRequirements from "./SiteLeaderRequirements";
import MessageModal from "../../../../Core/Forms/MessageModal";
import { setCheckedValues } from "../../../Common/Functions/FormFunctions";

export default function JobSheet() {
    const params = useParams();
	const location = useLocation();
	const store = useSessionStore();
    const navigate = useNavigate();

    const form = useForm({mode: "onChange", resolver: yupResolver(JobSheetValidationSchema)});
    const { handleSubmit, formState, getValues } = form;
    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);
    const [jobSheet, setJobSheet] = useState(null);   
    const [photos, setPhotos] = useState([[]]);    
    const [submitEnabled, setSubmitEnabled] = useState(false);    
    const [modal, setModal] = useState({show: false, header: "", message: ""});

    async function getJobSheet() {
		
        let result = await store.JobSheetsService.GetJobSheet(params.id);

        if (result && result.Data) {

            let formData = result.Data;
            
            if (formData.JobSheet.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            let newPhotos = [];

            for (let i = 0; i < formData.SalesQuote.Photos.length; i += 3) {

                let photoRow = formData.SalesQuote.Photos.slice(i, i + 3);

                newPhotos.push(photoRow);
            }

            setPhotos(newPhotos);

            setJobSheet({
                ...formData.SalesQuote,
                JobSheet: formData.JobSheet
            });
        }
	}

    useEffect(() => {

        getJobSheet();
	}, []);

    useEffect(() => {

        if (jobSheet) form.reset(jobSheet, {keepDirtyValues: true});
    }, [jobSheet]);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    const onSubmit = async () => {

        let jobSheetId = params.id ? params.id : null;

        if (!params.id) {
            jobSheetId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!jobSheetId) return;

        let result = await store.JobSheetsService.SubmitJobSheet(jobSheetId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Job Sheet submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const onError = (errors, e) => console.log(errors, e);

    const onSave = async (submit = false) => {

        const data = getValues();

        let saveJobSheet = {
            Id: data.JobSheet.Id,
            AdditionalNotes: data.JobSheet.AdditionalNotes,
            SiteLeaderRequirements: data.JobSheet.SiteLeaderRequirements
        }

        let result = await store.JobSheetsService.UpdateJobSheet(saveJobSheet);

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Job Sheet saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Job Sheet",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

    }

    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    } 

	return 	<PageContainer title="Projects" subtitle="Job Sheets">
				{jobSheet ?
                    <FormContainer formTitle="Job Sheet" formNumber={jobSheet && jobSheet.SalesQuoteNumber && `JS-EST${jobSheet.SalesQuoteNumber}`} viewOnly={viewOnly}>
                        <FormProvider { ...form }>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <FormSection title="">
                                    <AssignToTeamLeader isJobSheet value={jobSheet.TeamLeaderId}></AssignToTeamLeader>
                                </FormSection>
                                <SiteDetails disabled/>
                                <JobRequirements requirements={jobSheet && jobSheet.JobRequirements} disabled/>                            
                                <JobDescriptions
                                    disabled={true}
                                    isJobSheet
                                />
                                <JobProductDetails
                                    isJobSheet
                                    disabled={viewOnly}
                                />
                                <Photos
                                    disabled={true}
                                />
                                <SiteLeaderRequirements                                
                                    setSiteLeaderRequirements={(index, value) => {
                                        setJobSheet(
                                            {...jobSheet,
                                                JobSheet: setCheckedValues(jobSheet.JobSheet, index, value, "SiteLeaderRequirements")
                                            }
                                        )}}
                                    requirements={jobSheet && jobSheet.JobSheet?.SiteLeaderRequirements}
                                    disabled={viewOnly}                                    
                                />
                                <CompletedBySection 
                                    submittedToAdminOn={jobSheet.JobSheet?.SubmittedToAdminOn ? jobSheet.JobSheet?.SubmittedToAdminOn : ""}
                                    disabled={viewOnly}
                                    onSave={onSave}
                                    submitEnabled={submitEnabled}                                    
                                />
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
			</PageContainer>
}